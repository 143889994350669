<template>

  <div class="head pb grid_width "> 
 

      <div class="phead">

        <div class="ava">
          <div class="avac">
            <img             
            :src="props.avatar"
            class="avai" 
            :alt="props.nmalt"
            > 
            <!-- <div :class="'user_range u_range_' + props.status" :title="props.status && $constants.status[props.status] ? $t($constants.status[props.status]) : ''"></div> -->
          </div> 

          <div class="socials">
            <span class="soc" @click="copyLink" target="_blank"><i class="social_icon social_link"></i></span>
            <a class="soc" rel="nofollow noopener" v-if="props.socials.redirect_link_youtube" :href="props.socials.redirect_link_youtube" target="_blank"><i class="social_icon social_youtube"></i></a>  
            <a class="soc" rel="nofollow noopener" v-if="props.socials.redirect_link_facebook" :href="props.socials.redirect_link_facebook" target="_blank"><i class="social_icon social_facebook"></i></a>  
            <a class="soc" rel="nofollow noopener" v-if="props.socials.redirect_link_instagram" :href="props.socials.redirect_link_instagram" target="_blank"><i class="social_icon social_instagram"></i></a>  
            <a class="soc" rel="nofollow noopener" v-if="props.socials.redirect_link_twitter" :href="props.socials.redirect_link_twitter" target="_blank"><i class="social_icon social_twitter"></i></a>  
            <a class="soc" rel="nofollow noopener" v-if="props.socials.link_email" :href="'mailto:' + props.socials.link_email" target="_blank"><i class="social_icon social_email"></i></a>  
          </div> 
    
        </div>

        <div class="p_name">

          <div class="brand">
            <h1 class="bname">{{ nm }}</h1> 
          </div>

          <div class="ovr" v-if="props.slogan">
            {{ props.slogan }}
          </div>

          <div class="ovr status" v-else-if="$constants.status[props.status]">
            {{ $t($constants.status[props.status]) }}
          </div>


          

        </div>

        <!--  -->


        <div class="space1"></div>
        <div class="space2"></div>


        <!--  -->


        <div class="actions"> 
<!-- 
          <template v-if="props.officalUser">
            <NuxtLink class="user_official" :to="props.officalUser.link"> 
              <img class="avatar" :src="props.officalUser.avatar_little" :alt="props.officalUser.name">
              <div class="name">{{ props.officalUser.name }}</div> 
              <div class="status">Official account</div> 
            </NuxtLink>  
          </template> -->



<!--           
          <UiButton        
            @click="toChat"
            name="Chat"
            ico="icon-message"        
            />
    
             
          <UiButton
            :name="$t('universal_button_follow')"
            :cnt="props.cntFollowers"
            :type="'primary ' + (useAuth().isAuth() ? '' : 'outline')"
            v-if="!props.isfollow"
            @click.native="follow" 
            />

          <UiButton
            :name="$t('universal_button_unfollow')"
            :cnt="props.cntFollowers"
            type=""
            v-if="props.isfollow"
            @click.native="unfollow" 
            />
 -->


          <GrowerActionsEdit
            :id="props.id"        
            :owner="props.id"
            :chat-user="props.id"
            :link="props.link"
            :followers="props.cntFollowers"
            :is-follow="props.isFollow"
            @follow="follow"
            @unfollow="unfollow"
            @removeuser="emits('removeuser')"
            @unpublishuser="emits('unpublishuser')"
            @publishuser="emits('publishuser')"
            />




          </div>  


        <!--  -->

        <div class="stats mob">

          <NuxtLink :to="'/growers'" class="stat">
            <div class="vl">#{{ props.pos }}</div>
            <div class="tx">Global pos.</div>
          </NuxtLink>

          <div class="stat" v-if="props.cntHarvests">
            <div class="vl">
              {{ props.cntHarvests }}
            </div>
            <div class="tx">Harvests</div>
          </div>

          
        </div>

        <div class="stats total">

          <NuxtLink :to="props.catalogLink" class="stat rate" v-if="props.pos">
            <div class="vl">#{{ props.pos }}</div>
            <div class="tx">Global pos.</div>
          </NuxtLink>

          <NuxtLink :to="props.diariesLink"  class="stat" v-if="props.cntReports">
            <div class="vl">
              <i class="icon-diary" title="diaries"></i>
              {{ props.cntReports }}
            </div>
            <div class="tx">Diaries</div>
          </NuxtLink>

          <div class="stat" v-if="props.cntHarvests">
            <div class="vl">
              <i class="ic-har"></i>
              {{ props.cntHarvests }}
            </div>
            <div class="tx">Harvests</div>
          </div>

          <!-- <div class="stat stat_gp">
            <div class="vl">
              <i class="gp"></i>
              {{ props.weightPlant }}
            </div>
            <div class="tx">g/plant</div>
          </div> -->

          <div class="stat" v-if="cntYears">
            <div class="vl">
              <i class="icon-time"></i>
              {{ cntYears }}
            </div>
            <div class="tx">Growing, years</div>
          </div>

          <!-- <div class="stat">
            <div class="vl">
              {{ $dayjs.utc(props.visit).fromNow() }}  
            </div>
            <div class="tx">{{ $t('profile_view_info_last_visit') }}</div>
          </div> -->

          <div class="stat" v-if="props.continent">
            <div class="vl">
              {{ props.continent }}
            </div>
            <div class="tx">Region</div>
          </div>


          <div class="stat" v-if="props.cntAnswers">
            <div class="vl">
              <i class="icon-feed-problem" title="Solutions"></i>
              {{ props.cntAnswers }}
            </div>
            <div class="tx">Answers</div>
          </div>


          <!-- <div class="stat official" v-if="props.officalUser">
            <NuxtLink :to="props.officalUser.link" class="vl">
              <img class="img round" :src="props.officalUser.avatar_little" :alt="props.officalUser.name">
              {{ props.officalUser.name }}
            </NuxtLink>
            <div class="tx">Official account</div>
          </div> -->

 

          <div class="stat" v-if="props.autoPreferred">
            <div class="vl">
              <span class="shp auto" title="Autoflower">A</span>  
              {{ props.autoPreferred }}%
            </div>
            <div class="tx cap">
                Autoflower
            </div>
          </div>
  

          <div class="stat" v-if="props.photoPreferred">
            <div class="vl">
              <span class="shp photo" title="Photoperiod">P</span>  
              {{ props.photoPreferred }}%
            </div>
            <div class="tx cap">
              Photoperiod
            </div>
          </div>
  
  
          

          <div class="stat" v-if="props.indoorPreferred">
            <div class="vl">
              <i class="icon-home"></i>
              {{ props.indoorPreferred }}%
            </div>
            <div class="tx cap">
                Indoor
            </div>
          </div>
  

          <div class="stat" v-if="props.outdoorPreferred">
            <div class="vl">
              <i class="icon-sun"></i>
              {{ props.outdoorPreferred }}%
            </div>
            <div class="tx cap">
              Outdoor
            </div>
          </div>
  
  
           

        </div>

        <!--  -->

        <div class="preferred">
          <!-- {{ props.preferred }} -->
          <GrowerHeadGrowerPreferred
            :preferred="props.preferred"
            />
        </div> 
        
        <!--  -->

    


      </div>
         
  </div>
 

</template>

<script setup>

import { useClipboard } from '@vueuse/core'
const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
const { hooks, $popup } = useNuxtApp()
const { t } = useI18n();
const emits = defineEmits(['follow', 'unfollow'])

const props = defineProps({
  bread: {
    type: Array,
    default: [],
  },
  id: {
    type: Number,
    default: null,
  },
  status: {
    type: Number,
    default: null,
  },
  brand_package: {
    type: Number,
    default: null,
  },
  linkShop: {
    type: String,
    default: null,
  },
  background: {
    type: String,
    default: null,
  }, 
  nm: {
    type: String,
    default: null,
  },
  visit: {
    type: String,
    default: null,
  },
  cntHarvests: {
    type: Number,
    default: null,
  },
  fromYear: {
    type: Number,
    default: null,
  }, 
  cntReports: {
    type: Number,
    default: null,
  },
  cntAnswers: {
    type: Number,
    default: null,
  },
  weightPlant: {
    type: Number,
    default: null,
  },
  pos: {
    type: Number,
    default: null,
  },
  nmalt: {
    type: String,
    default: null,
  },
  slogan: {
    type: String,
    default: null,
  },
  avatar: {
    type: String,
    default: null,
  }, 
  link: {
    type: String,
    default: null,
  },
  rate: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
  rateof: {
    type: Number,
    required: false,
    default: 10,
  },
  isFollow: {
    type: Boolean,
    default: false,
  },
  cntFollowers: {
    type: Number,
    default: null,
  },
  officalUser: {
    type: Object,
    default: null,
  },
  category: {
    type: String,
    default: null,
  }, 
  categoryName: {
    type: String,
    default: null,
  }, 
  continent: {
    type: String,
    default: null,
  },
  diariesLink: {
    type: String,
    default: null,
  },
  catalogLink: {
    type: String,
    default: null,
  },
  itemsPhoto: {
    type: Array,
    default: null,
  },
  socials: {
    type: Object,
    default: null,
  },
  preferred: {
    type: Array,
    default: null,
  },
  autoPreferred: {
    type: Number,
    default: null,
  }, 
  photoPreferred: {
    type: Number,
    default: null,
  },
  indoorPreferred: {
    type: Number,
    default: null,
  }, 
  outdoorPreferred: {
    type: Number,
    default: null,
  },
})




const copyLink = function(){
  copy(document.location.href);
  $popup.success('Link copied');
}


const follow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  
  emits('follow')
}

const unfollow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  emits('unfollow')
}


 

const cntYears = computed(() => {
  return new Date().getFullYear() - props.fromYear;
});

 

</script>




<style scoped>
 
.pghd_gr3{

}
.head{
  padding: 0!important;
}
.head .head_bg{
  /* background-color: var(--un-background-color-gray); */
  
}

.head .head_bg .page_content {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.head .head_bg .head_info {
  display: flex;
  align-items: center;
  width: 100%;
}
.head .head_bg .item_name {
  display: flex;
  align-items: center;
  z-index: 12;
  position: relative;
}
.head .head_bg .item_name .item_logo {
  width: 130px;
  /* background-color: var(--un-background-color-gray); */
  background-color: white;
  padding: 2px;
  border-radius: 200px;
  margin-right: 1rem;
  margin-top: -43px;
  border: 4px var(--un-element-color-gray) solid;
  box-shadow: 0px 0px 20px #00000024;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.head .bg{
  height: 230px;
  margin-bottom: -64px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
} 

.cap {
  text-transform: capitalize;
} 
/*  */

.phead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 1rem;
  gap: 1rem 2rem;
  display: grid;
  grid-template-columns: 175px 1fr;
  grid-template-areas: 
    "ava space1 space1"
    "ava name actions"
    "ava stats_total stats_total"
    "ava preferred preferred"
    "ava space2 space2"
  ;
}

.phead .socials {
  display: flex;
  gap: 0 0.6rem;
  grid-area: socials;
}
.phead .socials .soc{
  filter: grayscale(1);
  opacity: 0.6;
  transition: 0.3s;
  aspect-ratio: 1/1;
  font-size: 1.1rem;
}
.phead .socials .soc:hover{
  filter: grayscale(0);
  opacity: 1;
}
.phead .preferred {
  grid-area: preferred;
  width: 100%;
  min-width: 0;
  margin-top: 1rem;
}
.phead .ava {
  width: 100%;
  padding: 2px;
  border-radius: 15px; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  grid-area: ava;
  position: relative;
  flex-direction: column;
  gap: 1rem;
}
.phead .ava .avac {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: var(--gd-diary-cover-color);
  border-radius: 50%;
}
.phead .ava .user_range {
  position: absolute;
  bottom: 10px;
  right: 10px;
  aspect-ratio: 1 / 1;
  width: 40px;
  height: 40px;
  padding: 0;
}
.phead .ava .nums{
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #000000eb;
  color: white;
  padding: 1px 8px;
  border-radius: 40px;
  /* font-size: 0.7rem; */
  opacity: 0.8;

}

.phead .ava .avai{
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1 / 1 ;
  object-fit: cover;
}
 
.phead .ava .avai.lg{ 
  object-fit: contain;

}
.phead .space1 {
  grid-area: space1;
}

.phead .space2 {
  grid-area: space2;
}

.phead .p_name {
  display: flex;
  flex-direction: column;
  grid-area: name;
}
 

.phead .ovr{
  /* color: var(--gd-text-light-gray-color); */
  color: var(--gd-text-gray-color);
  margin-top: 5px;
  /* font-style: italic; */
}
.phead .ovr.status{
  font-weight: bold;
  /* color: var(--gd-text-color); */
}
.phead .p_name .brand{
  display: grid;
  align-items: center;
  gap: 0 10px;
  grid-template-columns: 1fr;
  grid-template-areas: 
    "cname"
    "bname"
  ;

}
.phead .p_name .brand .cname{
  font-size: 0.9rem;
  grid-area: cname;
  margin-top: auto;
  align-self: flex-end;
}
.phead .p_name .brand .bname{

  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6rem;
  grid-area: bname;
  margin-bottom: auto;
  align-self: flex-start;
}

.phead .p_name .brand .blogo {
  width: 100%;
  padding: 2px;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  grid-area: logo;
}

.phead .p_name .brand .blogo .logo {
  
}

.phead .stat .gp {
  width: 30px;
}
.phead .stat .har {
  width: 30px;
}

.phead .stats.total {
  grid-area: stats_total;
}
.phead .stats.mob {
  grid-area: stats_mob;
  display: none;
}
.phead .stats {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  /* max-width: 660px; */
}
.phead .actions{
  display: flex;
  gap: 0 0.5rem;
  grid-area: actions;
 
}
.phead .stats .stat {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 45px;
}

.phead .stats .stat .vl {
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
}
.phead .stats .stat .vl .img{
  aspect-ratio: 1/1;
  width: 25px;
  object-fit: cover;
}
.phead .stats .stat .vl .img.round{
  border-radius: 50%;
}
.phead .stats .stat .icon-female {
  color: #ff0067;
} 
.phead .stats .stat .icon-male {
  color: #0068ff;
}
.phead .stats .stat .shp{
  font-size: 1.4rem; 
  font-family: Courier;
  text-transform: uppercase;
  font-weight: normal;
    line-height: 1.1rem;
}
.phead .stats .stat .shp.auto{
  color: #1eb353;
}
.phead .stats .stat .shp.photo{
  color: #a913d9;
}
.phead .stats .stat .vl .shp{  
  font-size: 1.6rem; 
}
.phead .stats .stat .tx .shp{  
  font-size: 1rem; 
}
.phead .stats .stat .tx {
  color: #898989;
  font-size: 0.8rem;
}


.phead .stats .stat.taste .vl {
  display: flex;
  align-items: center;
}

.phead .stats .stat.taste .vl .ico:nth-child(1) {
  width: 25px;
  height: 25px;
}

.phead .stats .stat.taste .vl .ico:nth-child(2) {
  width: 20px;
  height: 20px;
}

.phead .stats .stat.taste .vl .ico:nth-child(3) {
  width: 15px;
  height: 15px;
}

.phead .desc {
  color: #7c7c7c;
  max-width: 660px;
  grid-area: desc;
}
.phead .desc :deep(p){
  margin: 0;
}
.phead .desc a {
  color: var(--un-primary-color);
}


@container pb (max-width: 650px) {

  .head .breadcrumbs{
    display: none;
  }
 

  .phead{
    margin-top: 1rem;
    gap: 0.6rem 1rem;
    grid-template-columns: 48% auto;
    grid-template-areas: 
      "ava space1"
      "ava name"
      "ava stats_mob"
      "ava actions"
      "ava space2"
      "stats_total stats_total"
      "preferred preferred"
    ;
  }
  .phead .ava{
    width: 100%;
  }
  .phead{

  }
  .phead .actions{
    flex-wrap: wrap;
    width: 100%;
    gap: 0 0.2rem;
  }
  .phead .actions :deep(.buttons){
    gap: 0;
  }
  .phead .actions :deep(.btn){
    width: 100%;
  }


  .phead .desc {

  }


  .phead .stats.total {

  }
  .phead .stats.mob {
    display: flex;
    gap: 0.5rem;
  }

  .phead .stats .stat.stars {
    display: none;
  }
  .phead .stats .stat.rate {
    display: none;
  }

  .phead .stats.mob .vl{
    font-size: 1.3rem;
  }
  .phead .stats.mob .star_rate{
    width: 25px;
    height: 25px;
  }
}


/* BACKGROUND */

.head .bgbr{
margin-top: 1rem;
}


@media (max-width: 650px) {

  .head .bg{
    height: 180px;

    background-repeat: no-repeat;
    background-size: auto 140%;
    background-position-y: center;
    background-position-x: center;
  } 
}

@container pb (max-width: 600px) {

  .head .bgbr{

  }
  
}



/* brand_cover  */


.brand_cover{

}
.brand_cover .bc{
    width: 100%;
    border-radius: 15px;    
}
.brand_cover .bcm{
  display: none;
}




@container pb (max-width: 650px) {
  .brand_cover{

  }
  .brand_cover .bc{
      display: none; 
  }
  .brand_cover .bcm{ 
    max-width: none;
    width: calc(100% + 4rem);
    display: block;
    margin-left: -2rem;
    margin-top: -2rem;
    margin-bottom: -20px;
  }
}

/*  */





/* user_official */



.user_official {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-template-areas: 
    "u_avatar u_name"
    "u_avatar u_status"
  ;
  gap: 0 10px;
  align-items: center;

}

.user_official .avatar {
  border-radius: 50%;
  width: 100%;
  border: 2px white solid;
  grid-area: u_avatar;
}
 
/* .user_official .user_range {
    float: left;
    font-size: 1.6rem;
    margin-right: 15px;
    position: absolute;
    left: 20px;
    bottom: 2px;
} */

.user_official .name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #494949;
    grid-area: u_name;
}

.user_official .status {
    color: #9c9c9c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: u_status;
}


</style>
