<template>

  <SkGrowerOpened
    v-if="is_loading"
    />
  <div class="pbf" v-else-if="grower_data">
    

    <GrowerHeadGrower
      :id="grower_data.id"
      :nm="grower_data.name"
      :logo="grower_data.logo_m"
      :link="grower_data.link"
      :slogan="grower_data.slogan"
      :status="grower_data.status"
      :avatar="grower_data.avatar"
      :items-photo="grower_data.items_photo"
      :pos="grower_data.item_stat.rate"
      :rate="grower_data.item_stat.avg_rate"
      :weight-plant="grower_data.item_stat.avg_weight_plant"
      :cnt-followers="grower_data.item_stat.cnt_followers"
      :cnt-answers="grower_data.item_stat.cnt_answers"
      :cnt-reports="grower_data.item_stat.cnt_reports"
      :cnt-reports-owner="grower_data.item_stat.cnt_reports_owner"
      :cnt-harvests="grower_data.item_stat.cnt_harvests"
      :auto-preferred="grower_data.item_stat.auto_preferred"
      :photo-preferred="grower_data.item_stat.photo_preferred"
      :indoor-preferred="grower_data.item_stat.indoor_preferred"
      :outdoor-preferred="grower_data.item_stat.outdoor_preferred"
      :catalog-link="'/growers'"
      :diaries-link="'/grower/' + grower_data.name.toLowerCase()   + '/diaries'"
      :from-year="grower_data.item_stat.from"
      :last-visit="grower_data.item_stat.visit"
      :continent="grower_data.continent"
      :is-follow="grower_data?.addon?.follow"
      :socials="grower_data.socials"
      :preferred="grower_data.items_preferred_brand"
      @follow="follow(1)"
      @unfollow="follow(0)"
      >

 
    </GrowerHeadGrower>
    
    <div class="pb grid_width">   

    <UiTabs 
      :tabs="tabs" 
      :acttab="active_tab"
      />    

      <br>

    <NuxtPage 
      :data="grower_data"
      :id="grower_data.id"
      />
    </div>
      
    <GrowerContextMenu 
      :is-follow="grower_data?.addon?.follow" 
      :data="grower_data"
      @follow="follow(1)"
      @unfollow="follow(0)"
      />

  </div>
</template>

<script setup>


const route = useRoute();
const router = useRouter();
const { $api, $ga, $helper, $head , $follow} = useNuxtApp();
const { t } = useI18n();


var link = ref(route.params.id);
var active_tab = ref('info');
var grower_id = ref(route.params['id']);





const tabs = computed(() => {

  var items = [];



  items.push({
    id: 'info',
    name: t('profile_view_info_tab'),
    link: grower_data.value?.link + '',          
    count: '',
    icon: '',
  });



  if(grower_data.value?.id == useAuth().getId()){

    if(grower_data.value?.item_stat.cnt_reports_owner > 0){
      items.push({
        id: 'diaries',
        name: t('profile_view_slider_diaries'),
        link: grower_data.value?.link + '/diaries',
        count: grower_data.value?.item_stat.cnt_reports_owner,
        icon: '',
      });
    }
    
  }else{

    if(grower_data.value?.item_stat.cnt_reports > 0){
      items.push({
        id: 'diaries',
        name: t('profile_view_slider_diaries'),
        link: grower_data.value?.link + '/diaries',
        count: grower_data.value?.item_stat.cnt_reports,
        icon: '',
      });
    }
  }

  if(grower_data.value?.item_stat.cnt_reviews > 0){
    items.push({
      id: 'reviews',
      name: t('profile_view_reviews_tab'),
      link: grower_data.value?.link + '/reviews',
      count: grower_data.value?.item_stat.cnt_reviews,
      icon: '',
    });
  }

  if(grower_data.value?.item_stat.cnt_following > 0){
    items.push({
      id: 'following',
      name: t('profile_view_following_tab'),
      link: grower_data.value?.link + '/following',
      count: grower_data.value?.item_stat.cnt_following,
      icon: '',
    });
  }

  if(grower_data.value?.item_stat.cnt_followers > 0){
    items.push({
      id: 'followers',
      name: t('profile_view_followers_tab'),
      link: grower_data.value?.link + '/followers',
      count: grower_data.value?.item_stat.cnt_followers,
      icon: '',
    });
  }

  return items;
 
});


const follow = function(state) {  
  $follow.toggle('user', grower_data.value.id, state, {
    type: 'user',
    id: grower_data.value.id,
    avatar: grower_data.value.avatar_small, 
    name: grower_data.value.name, 
    link: grower_data.value.link, 
  })
  .then((response) => {    
    grower_data.value.addon.follow = state ? true : false; 
    grower_data.value.item_stat.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error')
  });
}
 

const loadData = async (id) => {
  return await $api.getGrowerByName(id);  
}


const { pending: is_loading,  data: grower_data } = await useLazyAsyncData('grower_data_' + grower_id.value, async () => await loadData(grower_id.value))


watch( () => grower_data, () => {
  useHead(useNuxtApp().$head.getGrowerInfo(grower_data.value));
}, {immediate: true, deep: true});
 
watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getGrowerInfo(grower_data.value));
}, {immediate: true, deep: true});


onBeforeUnmount(() => {
  grower_data.value = null;
})


</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
