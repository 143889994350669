<template>   

  <div class="sss pbf">
 


    <div class="pb">
      <div class="hd">
          <div class="lg skl"></div>
          <div class="inm">
            <div class="nm skl"></div>
            <div class="sb skl"></div>
          </div>
      </div>
      <div class="tbs">
        <div class="tb skl"></div>
        <div class="tb skl"></div>
        <div class="tb skl"></div>
        <div class="tb skl"></div>
      </div>
    </div>

    <!-- stat -->
    <div class="pb">
    <SkGrowerOpenedInfo/>
</div>



  </div>

</template>

 
  

<style scoped>

.hd{
  height: 101px;
  overflow: inherit;
  display: flex;
  align-items: center;
  gap: 20px;
}
.hd .lg{
  width: 100px;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 100px;
  background-color: #f2f2f2;
}
.hd .inm{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.hd .nm{
  height: 30px;
  width: 100%;
  max-width: 200px;
}
.hd .sb{
  height: 20px;
  width: 100%;
  max-width: 140px;
}

.tbs{
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.tbs .tb{
  width: 100px;
  height: 30px;
  border-radius: 10px;
}


.bre{
  width: 100px;
  height: 20px;
  margin-left: auto;
}

.inf{
  display: flex;
  align-items: flex-start;
    gap: 2rem;
}
.inf .pho{
  width: 250px;
  aspect-ratio: 1/0.9;
  flex-shrink: 0;
}
.inf .desc{
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}
.inf .desc .desc_rw{
  width: 50px;
  height: 50px;
}
.inf .desc .desc_rw:last-child{
  max-width: 80%;
}

@container pb (max-width: 600px) {
  .inf{
    flex-direction: column;
  }
  .hd .lg{
    width: 140px;
  }
  .inf .pho{
    width: 100%; 
  }
}

/* products */

.ttl{
  width: 140px;
  height: 20px;
  margin-bottom: 1rem;
}
.ls{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
}
.ls .lspr{
  width: calc(16.66667% - 20px);
  aspect-ratio: 1/1.5;
}



@container pb (max-width: 600px) {
  .ls .lspr{
    width: calc(50% - 20px);
  }  
}


</style>
